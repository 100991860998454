.form-login {
    display: flex;
    flex-direction: column;
    min-height: 40vh !important;
    width: 28vw !important;
    padding: 2rem !important;
    border-radius: 10px !important;
    background: rgba(83, 83, 83, 0.4) !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.959) !important;
    -webkit-backdrop-filter: blur(2px) !important;
    backdrop-filter: blur(2px) !important;
}
.img-login {
    height: 125px !important;
    margin-bottom: 10%;
    border-radius: 20%;
}
.btn-login {
    height: 6vh !important;
    color: #f3f3f3 !important;
    border: none !important;
    background-color: #162b75 !important;
    border-radius: 10px !important;
}
.btn-login:hover {
    background-color: #005baa !important;
}

.input-login {
    border-radius: 10px !important;
    height: 6vh !important;
    position: absolute;
    bottom: 0;
}

.chk-group-login {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    height: 10vh !important;
}
.chk-login {
    margin: 0 !important;
    font-weight: bold;
    font-size: 1.125rem !important;
}
.item-codigo {
    height: 100%;
}
.container-codigo {
    height: 15vh !important;
    width: 100% !important;
}
.caracter-codigo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5rem !important;
    border-radius: 10px;
    color: rgb(0, 0, 0) !important;
    background-color: rgba(255, 255, 255, 0.904) !important;
    border: 1px solid transparent;
}
.caracter-codigo--inactive {
    background-color: rgba(255, 255, 255, 0.356) !important;
}
