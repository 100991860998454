@import "~antd/dist/antd.css";

body {
    margin: 0;
    background-color: #f1f1f1;
}
.upload-credit {
    display: flex !important;
    column-gap: 10px;
    height: 40px !important;
}
.upload-credit > .ant-upload-list {
    column-gap: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 40px !important;
}
.ant-pagination {
    margin-top: 7px !important;
}
.modal_token > .ant-modal-content {
    border-radius: 2px !important;
}
.modal_token > .ant-modal-content > .ant-modal-body {
    padding: 0px !important;
}
.site-card-wrapper {
    padding: 25px 50px 10px;
    margin-bottom: 10px;
}

.ant-input-textarea > .ant-input {
    resize: none;
    height: 80px;
}

.ant-menu-inline > .ant-menu-item {
    font-size: 16px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    margin-top: 15px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #a6ce39;
    font-weight: bold;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: none;
}

.ant-card-head {
    background-color: #e0e0e0;
    //border-radius: 10px 10px 0px 0px;
}

/* .ant-card {
    border-radius: 10px;
} */

.list-dash .ant-list-items {
    background-color: #fff !important;
}

.list-dash .ant-list-item:nth-child(even) {
    background-color: #e0e0e0 !important;
}

.list-dash .ant-list-item {
    height: 145px;
}

::-webkit-scrollbar {
    width: 10px;
    //background-color: #f5f5f5;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;

    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table-style .ant-table-thead > tr > th {
    background: #e0e0e0 !important;
}

.table-style .ant-table-cell {
    background: #e0e0e0 !important;
}

.table-style .ant-table-tbody > tr > td:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-statistic {
    cursor: default;
    padding: 10px;
}
.ant-statistic-title {
    color: #f1f1f1;
    font-weight: bold;
}
.ant-statistic-content {
    display: flex;
    gap: 10px;
}
.ant-statistic-content-prefix {
    margin-top: 2px;
}

.input-mask {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
}
.input-mask:hover {
    border-color: #40a9ff;
    border-right-width: 1px;
}
.input-mask:focus {
    border-color: #008ed1;
    box-shadow: 0 0 0 2px rgb(0 142 209 / 20%);
    border-right-width: 1px !important;
    outline: 0;
}
.input-mask::placeholder {
    color: #c5c6c7 !important;
}
.input-mask[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px;
}
.input-mask[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-tabs-nav-wrap {
    padding: 25px 50px 0px;
}
